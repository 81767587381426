// extracted by mini-css-extract-plugin
export var lbContainerOuter = "L_wd";
export var lbContainerInner = "L_wf";
export var movingForwards = "L_wg";
export var movingForwardsOther = "L_wh";
export var movingBackwards = "L_wj";
export var movingBackwardsOther = "L_wk";
export var lbImage = "L_wl";
export var lbOtherImage = "L_wm";
export var prevButton = "L_wn";
export var nextButton = "L_wp";
export var closing = "L_wq";
export var appear = "L_wr";