// extracted by mini-css-extract-plugin
export var alignDiscLeft = "v_rv d_fp d_bG d_dv";
export var alignLeft = "v_qh d_fp d_bG d_dv";
export var alignDiscCenter = "v_rw d_fq d_bD d_dw";
export var alignCenter = "v_bP d_fq d_bD d_dw";
export var alignDiscRight = "v_rx d_fr d_bH d_dx";
export var alignRight = "v_qj d_fr d_bH d_dx";
export var footerContainer = "v_ry d_dW d_bW";
export var footerContainerFull = "v_rz d_dT d_bW";
export var footerHeader = "v_kf d_kf";
export var footerTextWrapper = "v_rB d_w";
export var footerDisclaimerWrapper = "v_km d_km d_ck";
export var badgeWrapper = "v_rC d_w d_bz d_bP d_bF d_bJ";
export var verticalTop = "v_rD d_bz d_bJ d_bN d_bL";
export var wide = "v_rF d_cy";
export var right = "v_rG d_bK";
export var line = "v_fk d_fl d_cv";
export var badgeDisclaimer = "v_rH d_bC d_bP d_bJ";
export var badgeContainer = "v_rJ d_bz d_bH d_bP";
export var badge = "v_rK";
export var padding = "v_rL d_c7";
export var end = "v_rM d_bH";
export var linkWrapper = "v_rN d_dB";
export var link = "v_mC d_dB";
export var colWrapper = "v_rP d_cx";
export var consent = "v_f d_f d_bC d_bP";
export var disclaimer = "v_rQ d_bz d_bJ";
export var media = "v_rR d_bx d_dy";
export var itemRight = "v_rS";
export var itemLeft = "v_rT";
export var itemCenter = "v_rV";
export var exceptionWeight = "v_rW H_tT";