// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "B_sX d_bD";
export var storyRowWrapper = "B_hx d_hx d_bK";
export var storyLeftWrapper = "B_sY d_bz d_bP";
export var storyWrapperFull = "B_sZ d_cD";
export var storyWrapperFullLeft = "B_pl d_cD d_bz d_bP d_bD";
export var contentWrapper = "B_mv d_hy";
export var storyLeftWrapperCenter = "B_s0 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "B_s1 d_hF";
export var storyHeader = "B_s2 d_hD d_w d_cs";
export var storyHeaderCenter = "B_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "B_hB d_hB d_by d_dw";
export var storyBtnWrapper = "B_s3 d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "B_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "B_sj d_fg d_Z";
export var imageWrapperFull = "B_s4 d_w d_H d_bf d_Z";
export var SubtitleSmall = "B_qd H_qd H_tr H_tD";
export var SubtitleNormal = "B_qf H_qf H_tr H_tF";
export var SubtitleLarge = "B_qg H_qg H_tr H_tG";
export var textLeft = "B_dv";
export var textCenter = "B_dw";
export var textRight = "B_dx";