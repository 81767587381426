// extracted by mini-css-extract-plugin
export var noPadding = "D_fj d_fj";
export var videoFrameWrapper = "D_tg d_kS d_b5";
export var videoIframeStyle = "D_pS d_d5 d_w d_H d_by d_b1 d_R";
export var videoLayoutRight = "D_th d_kR d_bK";
export var videoInnerWrapper = "D_tj d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5";
export var videoText = "D_tk d_dw d_w";
export var videoInnerInnerWrapper = "D_tl d_bz d_bD d_bJ";
export var btnWrapper = "D_d2 d_w d_bz d_bD d_cj";
export var btn = "D_mB d_b1 d_bz d_bD d_bP d_cH d_b6 d_dB d_n H_t1 H_tW";
export var icon = "D_p9 d_cC";
export var textLeft = "D_dv";
export var textCenter = "D_dw";
export var textRight = "D_dx";