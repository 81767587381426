// extracted by mini-css-extract-plugin
export var alignLeft = "C_qh d_fp d_bG d_dv";
export var alignCenter = "C_bP d_fq d_bD d_dw";
export var alignRight = "C_qj d_fr d_bH d_dx";
export var testimonialsContainer = "C_s5 d_dW";
export var testimonialsContainerFull = "C_s6 d_dT";
export var testimonialsMainHeader = "C_jw d_jw d_w d_cw";
export var testimonialsComponentParagraph = "C_jy d_jy";
export var testimonialsComponentSmall = "C_jx d_jx";
export var testimonialsComponentsWrapper = "C_js d_js d_y d_w d_cv d_b3 d_b5";
export var testimonialsComponentsWrapperDesign2 = "C_jt d_jt d_y d_w d_cv d_b3 d_b5 d_bC";
export var testimonialsComponentsWrapperDesign3 = "C_jv d_jv d_y d_w d_H d_b5 d_bT d_bz d_bJ d_bP d_bD";
export var testimonialsBackgroundColor = "C_s7 d_d7";
export var colEqualHeight = "C_s8 d_bz";
export var testimonialsColumnWrapper = "C_jz d_jz d_b3";
export var testimonialsImageWrapper = "C_jp d_jp d_w d_Z";
export var testimonialsImageWrapperDesign2 = "C_jq d_jq d_r d_Z d_bx";
export var testimonialsImageWrapperDesign3 = "C_jr d_jr d_Z d_bz";
export var design2TextWrapper = "C_sW d_bC d_bP d_bJ";
export var design3 = "C_s9 d_cD d_H";
export var imageDesign2 = "C_tb";
export var SmallSmall = "C_tc H_tc H_tr H_tP";
export var SmallNormal = "C_td H_td H_tr H_tQ";
export var SmallLarge = "C_tf H_tf H_tr H_tM";
export var exceptionWeight = "C_rW H_tT";