// extracted by mini-css-extract-plugin
export var customText = "r_q5 d_dv d_cs d_cg";
export var videoIframeStyle = "r_pS d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "r_q6 d_cs d_cg d_Z";
export var customRow = "r_qb d_bD d_bf";
export var quoteWrapper = "r_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "r_pX d_H";
export var masonryImageWrapper = "r_p3";
export var title = "r_q7";
export var Title3Small = "r_q8 H_q8 H_tr H_tz";
export var Title3Normal = "r_q9 H_q9 H_tr H_tB";
export var Title3Large = "r_rb H_rb H_tr H_tC";