// extracted by mini-css-extract-plugin
export var carouselContainer = "N_wv d_w d_H d_bf d_Z";
export var carouselContainerCards = "N_ww N_wv d_w d_H d_bf d_Z";
export var carouselContainerSides = "N_wx d_w d_H d_Z";
export var prevCarouselItem = "N_wy d_w d_H d_0 d_k";
export var prevCarouselItemL = "N_wz N_wy d_w d_H d_0 d_k";
export var moveInFromLeft = "N_wB";
export var prevCarouselItemR = "N_wC N_wy d_w d_H d_0 d_k";
export var moveInFromRight = "N_wD";
export var selectedCarouselItem = "N_wF d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "N_wG N_wF d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "N_wH N_wF d_w d_H d_Z d_bf";
export var nextCarouselItem = "N_wJ d_w d_H d_0 d_k";
export var nextCarouselItemL = "N_wK N_wJ d_w d_H d_0 d_k";
export var nextCarouselItemR = "N_wL N_wJ d_w d_H d_0 d_k";
export var arrowContainer = "N_wM d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "N_wN N_wM d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "N_wP N_wN N_wM d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "N_wQ N_wM d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "N_wR";
export var nextArrowContainerHidden = "N_wS N_wQ N_wM d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "N_kG d_0";
export var prevArrow = "N_wT N_kG d_0";
export var nextArrow = "N_wV N_kG d_0";
export var carouselIndicatorContainer = "N_wW d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "N_wX d_w d_bz d_bF";
export var carouselText = "N_wY d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "N_wZ N_wY d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "N_w0 d_b7";
export var carouselIndicator = "N_w1 N_w0 d_b7";
export var carouselIndicatorSelected = "N_w2 N_w0 d_b7";
export var arrowsContainerTopRight = "N_w3 d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "N_w4 d_0 d_bl d_bC";
export var arrowsContainerSides = "N_w5 d_0 d_bl d_bC";
export var smallArrowsBase = "N_w6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "N_w7 N_w6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "N_w8 N_w7 N_w6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "N_w9 N_w6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "N_xb N_w9 N_w6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "N_p9";
export var iconSmall = "N_xc";
export var multipleWrapper = "N_xd d_bC d_bF d_bf";
export var multipleImage = "N_xf d_bC";
export var sidesPrevContainer = "N_xg N_w7 N_w6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "N_xh N_w7 N_w6 d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";